import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";

// Icons
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaReact, FaKaggle } from "react-icons/fa";
import { SiTailwindcss, SiFigma, SiNextdotjs, SiLeetcode } from "react-icons/si";
import {AiFillInstagram, AiFillGithub} from "react-icons/ai"

// Pics
import pic from "../../Assets/pic-gfg-hd.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const HomePage = () => {
  const [text] = useTypewriter({
    words: [
      "Full Stack Developer,",
      "Data Analyst,",
      "Machine Learning Engineer,",
      "Deep Learning Engineer,",
      "Trader"
    ],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  return (
    <>
    <div id="home" className="flex justify-center text-yellow-500 items-center md:pt-10 pt-4 md:text-xl">About</div>
      <div
        className="w-full md:flex md:px-10 py-10 md:justify-start md:items-start px-3"
      >        
        <div className="md:w-2/5 flex justify-center md:justify-normal blur-load">
          <img style={{ width: "70%" }} src={pic} alt="Logo1" loading="lazy" />
        </div>
        <div className="md:w-3/5 flex items-start flex-col md:gap-5 gap-2">
          <div className="text-gray-300">
            <p className="lg:text-5xl md:text-3xl text-2xl">
              <span className="lg:text-3xl md:text-2xl text-lg font-semibold">
                Hey There!{" "}
              </span>{" "}
              I'm{" "}
              <span className="text-yellow-400 font-extrabold uppercase">
                Naveen Kumar
              </span>
            </p>
          </div>
          <div className="lg:text-5xl md:text-3xl text-2xl font-semibold">
            <p>
              a <span>{text}</span>
              <Cursor
                cursorBlinking="false"
                cursorStyle="|"
                cursorColor="#FFFF00"
              />
            </p>
          </div>
          <div className="md:text-lg text-sm py-2 md:py-3">
            I am a highly skilled software developer with Industrial experience
            over{" "}
            <span className="text-yellow-400 font-extrabold capitalize">
              2+ years
            </span>
            . In previous roles, I ensured
            <span className="text-yellow-400 font-extrabold capitalize">
              {" "}
              98% on-time project completion
            </span>
            . I also have experience in{" "}
            <span className="text-yellow-400 font-extrabold capitalize">
              Agile
            </span>{" "}
            Software Development.
          </div>
          <div className="w-full flex flex-col justify-between lg:flex-row">
            <div className="w-full">
              <p>Connect With me</p>
              <div className="flex gap-4 lg:py-8 md:py-7 py-5">
                <a href="https://www.linkedin.com/in/naveenkaranamu/" target="_blank" rel="LinkedIn">
                <span className="homeIcon w-10 h-10 md:w-16 md:h-16">
                  <FaLinkedinIn />
                </span>
                </a>
                <a href="https://twitter.com/NaveenKaranamu" target="_blank" rel="Twitter">
                <span className="homeIcon w-10 h-10 md:w-16 md:h-16">
                  <FaTwitter />
                </span>
                </a>
                <a href="https://www.instagram.com/naveen_karanamu/" target="_blank" rel="Instagram">
                <span className="homeIcon w-10 h-10 md:w-16 md:h-16">
                  <AiFillInstagram />
                </span>
                </a>
              </div>
            </div>
            <div className="w-full">
              <p>Tech. Platforms</p>
              <div className="flex gap-4 lg:py-8 md:py-7 py-5">
                <a href="https://leetcode.com/naveen-karanamu/" target="_blank" rel="Leetcode">
                <span className="homeIcon w-10 h-10 md:w-16 md:h-16">
                  <SiLeetcode />
                </span>
                </a>
                <a href="https://github.com/Naveen-Karanamu" target="_blank" rel="Github">
                <span className="homeIcon w-10 h-10 md:w-16 md:h-16">
                  <AiFillGithub />
                </span>
                </a>
                <a href="https://www.kaggle.com/naveenkaranamu" target="_blank" rel="Kaggle">
                <span className="homeIcon w-10 h-10 md:w-16 md:h-16">
                  <FaKaggle />
                </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
