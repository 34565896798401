import React from "react";
import {motion} from "framer-motion"
import ResumeCard from "../../Cards/Resume/ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div className="px-4 md:px-6 lg:px-14 md:w-2/3">
        <div className="flex flex-col gap-4">
          <h2 className="text-4xl font-bold">Work Experience</h2>
          <p className="text-sm text-designColor tracking-[4px]">2020 - Present</p>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-designColor border-opacity-30 flex flex-col gap-10">
        <ResumeCard
            title="Software Engineering Associate"
            subTitle="British Telecom (BT Groups) | [July 2024 - Present]"
            result="Bengaluru, Karnataka"
            des="Started working as Software Engineering Associate
            "
          />
          <ResumeCard
            title="Software Engineering Intern"
            subTitle="British Telecom (BT Groups) | [January 2024 - July 2024]"
            result="Bengaluru, Karnataka"
            des="Worked as a Backend Developer in the Private cloud of BT, Worked on their various Microservices, Code Fixes, Code smells and Code Vulnerabilities
            "
          />
          <ResumeCard
            title="Technical Team Lead"
            subTitle="GeeksForGeeks SRM | [June 2022 - Feb 2024]"
            result="Chennai, TN"
            des="Took a Workshop on Backend. Built APIs and performed CRUD operations,
            Implemented Microservices and Used MongoDB as the database. 
            Took a Seminar on the Roadmaps for a Full Stack Web Developer & Data Scientist. 
            Took an Internal Workshop on React JS, Git, Github"
          />
          <ResumeCard
            title="Machine Learning Engineer & Full Stack Web Developer"
            subTitle="MarketInc | [October 2022 - January 2023]"
            result="Remote"
            des="Worked on the ERP System to develop a Service based Web Site using MERN Stack
            • Integrated company’s data with [National Electricity Marketing]’s data and Reverse-engineered OpenNEM API's
            • Developed APIs to extract data and Worked on Data Fetching, Data Cleaning, Data Exploration, Data
            Manipulation, Data Analysis by using Data Visualization."
          />
          <ResumeCard
            title="Full Stack Web Developer"
            subTitle="Zapezzy | [May 2022 - July 2022]"
            result="Remote"
            des="Stack Developer Intern Remote
            • Worked on MERN Stack, Next JS Refresh Tokens and TypeScript to develop a service based Web Site and Web Sockets to integrate them into the Chat
            System
            • Worked on the AWS services, s3, EC2 for image file storage and handling development of APIs in the Backend."
          />
        </div>
      </div>
      
    </motion.div>
  );
};

export default Experience;
