import axios from "axios";

// Type
import { ADD_MESSAGE } from "./message.type";

export const addMessage = (msgData) => async (dispatch) => {
  try {
    const Message = await axios({
      method: "POST",
        url: `https://naveen-karanamu-be.vercel.app/msg/new`,
      // url: `http://localhost:3001/msg/new`,
      data: { newMessage: msgData },
    });
    return dispatch({
      type: ADD_MESSAGE,
      payload: Message.data,
      status: "registered",
    });
  } catch (error) {
    return dispatch({ type: "ERROR", payload: error, status: "ERROR" });
  }
};
