import React from "react";
import WorksCard from "../Cards/WorksCard/WorksCard";
import { FaLaptopHouse } from "react-icons/fa";
import { WorksData } from "../Cards/WorksCard/WorksData";

import HeroSlider from "react-slick";

import { NextArrow, PrevArrow } from "../Slider/Arrows";

export const WorksPage = () => {
  const settings = {
    arrows: true,
    centerMode: true,
    centerPadding: "0px",
    dots: FaLaptopHouse,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    // nextArrow=<NextArrow />,
    // prevArrow=<PrevArrow />
  };

  const settingsLg = {
    outerEdgeLimit: false,
    arrows: true,
    centerMode: true,
    centerPadding: "1px",
    dots: FaLaptopHouse,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div id="works" className="">
      <div
        id="home"
        className="flex justify-center text-yellow-500 items-center md:pt-10 pt-4 md:text-xl"
      >
        My Works
      </div>
      {/* <HeroSlider {...settings}> */}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20 px-10 py-5 md:py-10  justify-center">
          {WorksData.map((item) => (
            <WorksCard item={item} key={item.id} />
          ))}
        </div>
      {/* </HeroSlider> */}
    </div>
  );
};
