import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./Components/Pages/HomePage";
import {WorksPage} from "./Components/Pages/WorksPage";
import ContactPage from "./Components/Pages/ContactPage";
import EventsPage from "./Components/Pages/EventsPage";
import ProjectsPage from "./Components/Pages/ProjectsPage";
import ResumePage from "./Components/Pages/Resume/ResumePage";
import HomeHOC from "./HOC/HomeHOC"; 

function App() {
  return (
    <Router>
      <Switch>
        <HomeHOC path="/" exact component={HomePage} />
        <HomeHOC path="/works" exact component={WorksPage} />
        <HomeHOC path="/projects" exact component={ProjectsPage} />
        <HomeHOC path="/resume" exact component={ResumePage} />
        <HomeHOC path="/events" exact component={EventsPage} />
        <HomeHOC path="/contact" exact component={ContactPage} />
      </Switch>
    </Router>
  );
}

export default App;

