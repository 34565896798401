export const navLinksdata = [
    {
      _id: 1001,
      title: "Home",
      link: "",
    },
    {
      _id: 1002,
      title: "Works",
      link: "works",
    },
    {
      _id: 1003,
      title: "Projects",
      link: "projects",
    },
    {
      _id: 1004,
      title: "Resume",
      link: "resume",
    },
    {
      _id: 1006,
      title: "Events",
      link: "events",
    },
    {
      _id: 1007,
      title: "Contact",
      link: "contact",
    },
  ];