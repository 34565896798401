import React from "react";
import ProjectsCard from "../Cards/Projects/ProjectsCard";
import bms from "../../Assets/Projects/bms.png";
import twitter from "../../Assets/Projects/twitter.png";
import zomato from "../../Assets/Projects/zomato.png";
import pan from "../../Assets/Projects/pan.png";
import senti from "../../Assets/Projects/senti.jpg";
import face from "../../Assets/Projects/face.webp";

const ProjectsPage = () => {
  return (
    <section id="projects" className="w-full ">
      <div
        id="home"
        className="flex justify-center text-yellow-500 items-center md:py-10 pt-4 md:text-xl"
      >
        My Projects
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14 px-4 md:px-6 lg:px-10">
        <ProjectsCard
          title="BookMyShow FrontEnd Clone"
          des=" Used React Js and Tailwind CSS to build the Fully Responsive Frontend, Axios to fetch the data
          • Used React Context for State Management and TMDB Database for Movie’s data"
          glink="https://github.com/Naveen-Karanamu/BookMyShow-clone"
          wlink="https://book-my-show-webapp.vercel.app/"
            src={bms}
        />
        <ProjectsCard
          title="Zomato Full Stack Clone"
          des=" • Used React Js and Tailwind CSS to build the Fully Responsive Frontend and Node Js, Express Js, and
          REST-APIs in the Backend Development and MongoDB, Mongoose and AWS as the Database’s
          • Used Redux for State Mangement and Extra services used MailGun, Google-O-Auth, Razorpay, JOI
          validation"
          glink="https://github.com/Naveen-Karanamu/Zomato-Full-Stack-Clone"
            src={zomato}
        />
        <ProjectsCard
          title="Mini Full Stack Twitter Clone"
          des="• Used React Js and Tailwind CSS to build the Fully Responsive Frontend and Node Js, Express Js, and
          REST-APIs in the Backend Development
          • Used MongoDB, Mongoose and AWS as the Database’s and Redux for State Mangement and JOI validation"
          glink="https://github.com/Naveen-Karanamu/Twitter-Clone"
            src={twitter}
        />
        <ProjectsCard
          title="PAN Card Tampering Detection"
          des="• Used Python to develop the model and Flask for the Backend, HTML and Tailwind for the Frontend
          • SSIM Algorithm is used to detect the Percentage of Tamperin"
          glink="https://github.com/Naveen-Karanamu/PAN-Card-Tampering-Detection"
            src={pan}
        />
        <ProjectsCard
          title="Sentimental Analysis"
          des="• Used Natural Language Processing to analyze the sentiments and Random Forest Classifier for the Classification
          • Used NLTK Library to process the NLP and MatplotLib Seaborn for Data Visualization"
          glink="https://github.com/Naveen-Karanamu/Sentimental-Analysis-NLP"
            src={senti}
        />
        <ProjectsCard
          title="Face Recognition"
          des="• Used OpenCV for Face Recognition Face Detection and cv2 Library to implement Computer Vision
• Used Pandas, Numpy for Data Pre-Processing and OS for local image file uploading"
          glink="https://github.com/Naveen-Karanamu/Face-Recognision-OpenCV"
            src={face}
        />
      </div>
     <a href="https://github.com/Naveen-Karanamu?tab=repositories" target="_blank" rel="Github">
     <div className="flex justify-center items-center">
        <div className="text-yellow-500 hover:text-designColor  border-2  mt-6 py-4 rounded-md w-fit px-20 hover:bg-black">
          More Projects
        </div>
      </div>
     </a>
    </section>
  );
};

export default ProjectsPage;
