import React, { useState } from "react";
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { AiFillInstagram, AiFillGithub } from "react-icons/ai";
import pic from "../../Assets/pic-gfg-hd.png";

import { addMessage } from "../../Redux/Reducer/Message/message.action";

import { useDispatch } from "react-redux";

const ContactPage = () => {
  const [name, setUsername] = useState("");
  const [number, setPhoneNumber] = useState("");
  const [mail, setEmail] = useState("");
  const [sub, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const dispatch = useDispatch();

  // Email Validation
  const emailValidation = () => {
    return String(mail)
      .toLocaleLowerCase()
      .match(/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/);
  };

  const handleSend = async (e) => {
    e.preventDefault();
    if (name === "") {
      setErrMsg("name is required!");
    } else if (number === "") {
      setErrMsg("Phone number is required!");
    } else if (mail === "") {
      setErrMsg("Please give your Email!");
    } else if (!emailValidation(mail)) {
      setErrMsg("Give a valid Email!");
    } else if (sub === "") {
      setErrMsg("Plese give your Subject!");
    } else if (message === "") {
      setErrMsg("Message is required!");
    } else {
      const postMsg={
        name, number,mail, sub, message
      }
      const dispatchData = await dispatch(addMessage(postMsg));
      dispatchData.status==="registered"
      ?setSuccessMsg(
        `Thank you dear ${name}, Your Messages has been sent Successfully!`
      )
      :console.log(dispatchData.payload);

      setErrMsg("");
      setUsername("");
      setPhoneNumber("");
      setEmail("");
      setSubject("");
      setMessage("");
    }
  };
  return (
    <section id="contact" className="w-full ">
      <div
        id="home"
        className="flex justify-center text-yellow-500 items-center md:py-10 py-4 md:text-xl"
      >
        Contact Me At
      </div>
      <div className="w-full">
        <div className="w-full h-auto flex flex-col lg:flex-row justify-between lg:gap-10 lg:px-12 md:px-8 px-4">
          {/* Left part */}
          <div className="w-full lgl:w-[35%] h-full bg-gradient-to-r from-[#000] to-[#] p-4 lgl:p-8 rounded-lg shadow-shadowOne flex flex-col gap-8 justify-center ">
            <img
              className="w-1/2  object-cover rounded-lg mb-2 "
              src={pic}
              alt="contactImg"
              loading="lazy"
            />
            <div className="flex flex-col gap-4">
              <h3 className="text-3xl font-bold text-white">
                Naveen Kumar Karanamu
              </h3>
              <p className="text-lg font-normal text-white">
                Software Developer
              </p>
              <p className="text-base text-gray-400 tracking-wide">
                Talks about Web Development, Data Analytics, Machine Learning,
                Deep Learning, Artificial Intelligence, Data Structures and
                Algorithms, Trading ...
              </p>
              {/* <p className="text-base text-gray-400 flex items-center gap-2">
                Phone: <span className="text-lightText">+91 9491384712</span>
              </p> */}
              <p className="text-base text-white flex items-center gap-2">
                mail:{" "}
                <span className="hover:text-designColor">
                  naveenkaranamu@gmail.com
                </span>
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <h2 className="text-base uppercase font-titleFont mb-4">
                Find me in
              </h2>
              <div className="flex gap-4">
                <span className="bannerIcon">
                  <a
                    href="https://github.com/Naveen-Karanamu"
                    target="_blank"
                    rel="Github"
                  >
                    <AiFillGithub className="md:w-6 md:h-6 hover:text-designColor" />
                  </a>
                </span>
                <span className="bannerIcon">
                  <a
                    href="https://twitter.com/NaveenKaranamu"
                    target="_blank"
                    rel="Twitter"
                  >
                    <FaTwitter className="md:w-6 md:h-6 hover:text-designColor" />
                  </a>
                </span>
                <span className="bannerIcon">
                  <a
                    href="https://www.linkedin.com/in/naveenkaranamu/"
                    target="_blank"
                    rel="LinkedIn"
                  >
                    <FaLinkedinIn className="md:w-6 md:h-6 hover:text-designColor" />
                  </a>
                </span>
                <span className="bannerIcon">
                  <a
                    href="https://www.instagram.com/naveen_karanamu/"
                    target="_blank"
                    rel="Instagram"
                  >
                    <AiFillInstagram className="md:w-6 md:h-6 hover:text-designColor" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full lgl:w-[60%] h-full py-10 bg-gradient-to-r from-[#] to-[#] flex flex-col gap-8 p-4 lgl:p-8 rounded-lg shadow-shadowOne">
            <form className="w-full flex flex-col gap-4 lgl:gap-6 py-2 lgl:py-5">
              {errMsg && (
                <p className="py-3 bg-gradient-to-r from-[#] to-[#] shadow-shadowOne text-center text-red-600 text-base tracking-wide animate-bounce">
                  {errMsg}
                </p>
              )}
              {successMsg && (
                <p className="py-3 bg-gradient-to-r from-[#] to-[#] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                  {successMsg}
                </p>
              )}
              <div className="w-full flex flex-col lgl:flex-row gap-10">
                <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                  <p className="text-sm text-white uppercase tracking-wide">
                    Your name
                  </p>
                  <input
                    onChange={(e) => setUsername(e.target.value)}
                    value={name}
                    className={`${
                      errMsg === "name is required!" &&
                      "outline-designColor"
                    } contactInput`}
                    type="text"
                  />
                </div>
                <div className="w-full lgl:w-1/2 flex flex-col gap-4">
                  <p className="text-sm text-white uppercase tracking-wide">
                    Phone Number
                  </p>
                  <input
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    value={number}
                    className={`${
                      errMsg === "Phone number is required!" &&
                      "outline-designColor"
                    } contactInput`}
                    type="text"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-sm text-white uppercase tracking-wide">
                  Email
                </p>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  value={mail}
                  className={`${
                    errMsg === "Please give your Email!" &&
                    "outline-designColor"
                  } contactInput`}
                  type="email"
                />
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-sm text-white uppercase tracking-wide">
                  Subject
                </p>
                <input
                  onChange={(e) => setSubject(e.target.value)}
                  value={sub}
                  className={`${
                    errMsg === "Plese give your Subject!" &&
                    "outline-designColor"
                  } contactInput`}
                  type="text"
                />
              </div>
              <div className="flex flex-col gap-4">
                <p className="text-sm text-white uppercase tracking-wide">
                  Message
                </p>
                <textarea
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                  className={`${
                    errMsg === "Message is required!" && "outline-designColor"
                  } contactTextArea`}
                  cols="30"
                  rows="8"
                ></textarea>
              </div>
              <div className="w-full">
                <button
                  onClick={handleSend}
                  className="w-full h-12 bg-[#141518] rounded-lg text-base text-gray-400 tracking-wider uppercase hover:text-white duration-300 hover:border-[1px] hover:border-yellow-500 border-transparent hover:bg-designColor"
                >
                  Send Message
                </button>
              </div>
              {errMsg && (
                <p className="py-3 bg-gradient-to-r from-[#] to-[#] shadow-shadowOne text-center text-red-600 text-base tracking-wide animate-bounce">
                  {errMsg}
                </p>
              )}
              {successMsg && (
                <p className="py-3 bg-gradient-to-r from-[#] to-[#] shadow-shadowOne text-center text-green-500 text-base tracking-wide animate-bounce">
                  {successMsg}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactPage;
