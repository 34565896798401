import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "../../Cards/Resume/ResumeCard";

const Achievement = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="py-12 font-titleFont flex gap-20"
    >
      <div className="px-4 md:px-6 lg:px-14 md:w-2/3">
        <div className="py-12 font-titleFont flex flex-col gap-4 ">
          <h2 className="text-4xl font-bold">My Achievements</h2>
        </div>
        <div className="mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="30 Days of google Cloud"
            subTitle="Google | Google Cloud"
            result="Completed"
            des="I hands-on practice with Google Cloud. Learned good new Skills in Machine Learning & Big Data"
          />
          <ResumeCard
            title="Won Inter College Debate Compitition"
            subTitle="Millan'21 | SRM University"
            result="Event Winner"
            des="Won the Debate in the Biggest Cultural Events in the Fest Millan'21 amoung 50+ teams"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Achievement;
