import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk'

import rootReducer from "./Reducer/rootReducer";

// Middlewares
const middlewares=[thunk];

const store=createStore(rootReducer, {}, applyMiddleware(...middlewares));

export default store;