import { ADD_MESSAGE } from "./message.type";

const INITIAL_STATE = {};

const msgReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return { ...state };

    default:
      return { ...state };
  }
};

export default msgReducer;