import { AiFillApi, AiOutlineRobot, AiFillDatabase } from "react-icons/ai";
import { FaLaptopCode, FaRobot} from "react-icons/fa";
import {BsGraphUp} from "react-icons/bs"
import {VscGraphLine} from "react-icons/vsc"

// Features Data
export const WorksData = [
  {
    id: 1,
    icon: <FaLaptopCode />,
    title: "Front End Development",
    des: "I have worked on HTML 5, CSS 3, JavaScript, React Js, Tailwind CSS, Bootstrap, Next Js, React-Libraries, Client Side Rendering",
  },
  {
    id: 2,
    icon: <AiFillApi />,
    title: "Backend Development",
    des: "I have worked on Node Js, Express Js, TypeScript, JWT tokens, Authenticaiton, RESTful APIs, Private Routing, Socket Io, Refesh Tokens, MongoDb, Mongoose, SQL ServerSide Rendering",
  },
  {
    id: 3,
    icon: <BsGraphUp />,
    title: "Data Analytics",
    des: "I have worked on Python, Pandas, Numpy, Matplotlib, Seaborn, Keras, Scipy, scikit learn, Regex, Data Exploration, Data Cleaning, Data Extraction, Data Visualization, File Handling"
  },
  {
    id: 4,
    icon: <AiOutlineRobot />,
    title: "Machine Learning",
    des: "I have worked on Statistics, Probability, Supervised Learning Algorithms (Regressions, SVMs, SVRs, Decision Tree, Random Forest), Unsupervised Learning Algorithms (Clustering (K-Means), Association (Apriori, Eclat))",
  },
  {
    id: 5,
    icon: <FaRobot />,
    title: "Deep Learning",
    des: "I have worked on Neural Networks (ANN, CNN, RNN), Computer Vision, Natural Language Processing, Artificial Intelligence, Cuda, Tensorflow)",
  },
  {
    id: 6,
    icon: <AiFillDatabase />,
    title: "Data Structures & Algorithms",
    des: "I have worked on DSA in the languages C/C++. Have knowlege on good no.of Algorithms and currently working on CP.",
  },
  {
    id: 7,
    icon: <VscGraphLine />,
    title: "Trading",
    des: "I do analysis on Indian Stocks and Forex",
  },
];
