import React from "react";
import CommonNav from "../Components/Navbar/CommonNav";
import Footer from "../Components/Footer/Footer";

const HomeLayout=(props)=>{
    return (
        <>
        <CommonNav />
        {props.children}
        <Footer />
        </>
    )
}

export default HomeLayout;