import React from "react";
import geeko from "../../Assets/Events/Geekophilia.jpg";
import bb from "../../Assets/Events/BB.png";
import deepDive from "../../Assets/Events/Deep-Dive-AI.jpg";
import EventsCard from "../Cards/Events/EventsCard";

const EventsPage = () => {
  return (
    <section id="projects" className="w-full">
      <div
        id="home"
        className="flex justify-center text-yellow-500 items-center md:py-10 pt-4 md:text-xl"
      >
        Hosted & Organised Events
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14 px-4 md:px-6 lg:px-10">
        <EventsCard
          title="Deep Dive Into AI"
          des="Recently I was invited by Rajalakshmi Engineering College as a Speaker to conduct a 7+ hours Hands-On workshop On Machine Learning & Gave an overview about The Data Science 🧑🏼‍💻

          We implemented and talked about how to Extract, Explore, Clean, Visualise and Analyse the Data. Then we moved to How to do Machine Learning, the process and its steps 📊
          
          We made two Mini Projects 📈
          one was built on a Supervised Machine Learning Model i.e, Support Vector Machine in which we used Support Vector Regressor on a Dataset which consists of the people's salary data along with their specific personal data.
          The second one was built on The Unsupervised Machine Learning Model, which uses The K-Means-Clustering on a Customer's dataset of a Mall, which divides the customers into clusters and predicts the outputs.
          
          I had a great experience taking over the workshop with a great audience who listened and simultaneously built both the models along with me 💯
          "
          src={deepDive}
          link="https://www.linkedin.com/feed/update/urn:li:activity:7166723155841994752/"
        />
        <EventsCard
          title="Geek-O-Philia"
          des="I got an opportunity to conduct a Workshop on Data Science & Full Stack Web Development. There were nearly 150+ amazing people who made the event very Interesting with their beautiful questions. We had a fantastic session. We went through the complete Roadmap, Required Technologies, Required Skills, Myths & Facts, Different Job Roles and many more.
          "
          src={geeko}
          link="https://www.linkedin.com/feed/update/urn:li:activity:6997923197316730881/"
        />
        <EventsCard
          title="Breaking Backend"
          des="I conducted a 6 hours+ Fully Hands-On workshop 👨🏻‍💻 on Backend Web Development. The gist of the event is, started with the Introduction to Javascript, built API's using Node JS and Express JS, Connected MongoDB using Mongoose, implemented Microservices and finally ended up building a Mini Back-end Project.

          There were 80+ people who were simultaneously working on the live Mini project."
            src={bb}
            link="https://www.linkedin.com/feed/update/urn:li:activity:7064110131197739008/"
        />
      </div>
    </section>
  );
};

export default EventsPage;
