import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "../../Cards/Resume/ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full md:w-2/3 flex flex-col lg:flex-row md:justify-center md:items-center gap-10 lg:gap-20 px-4 md:px-8 lg:px-14"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <h2 className="text-3xl md:text-4xl font-bold">
            Education Qualifications
          </h2>
          <p className="text-sm text-designColor tracking-[4px]">
            2017 - Present
          </p>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-designColor border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="B.Tech in CSE with Specialization in AI & ML"
            subTitle="SRM University KTR [2020-2024]"
            result="CGPA - [9.15/10] (till VI th Semister)"
            des="I am currently Pursuing my Bachelor's Degree in Computer Science and Engineering with Specialized Subjects of Aritficial Intelligence & Machine Learning"
          />
          <ResumeCard
            title="Secondary High School | PCM | XII th"
            subTitle="Sri Chaitanya [2019-2020] | BIEAP"
            result="Percentage - 95.60% with CGPA - [10/10]"
            des="I Completed my Secondary Higher Education in Physics, Mathematics & Chemistry"
          />
          <ResumeCard
            title="Primary High School | X th"
            subTitle="DAV Public School [2017-2018]"
            result="Percentage - 91% | CBSE"
            des="I had the Responsibilities of - HOUSE PREFECT [2016-2017] & HOUSE VISE CAPTAIN [2017-2018]"
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
