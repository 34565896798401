import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../Assets/logo1.png";
import { navLinksdata } from "../constants";
import "../../index.css";

const CommonNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const styles = {
    section: {
      background:
        "linear-gradient(90deg, rgba(0, 0, 0, 1) 50%, rgba(18, 18, 103, 1) 75%, rgba(18, 18, 168, 1) 92%)",
    },
  };

  return (
    <>
      <div
        className="flex justify-between md:justify-normal lg:px-10 md:px-6 md:py-2 items-center border-b-2 border-gray-600 pb-2 font-bodyFont sticky top-0 bg-navBG z-10"
        style={styles.section}
      >
        <div className="hidden md:flex gap-3 lg:gap-16 md:gap-12 w-full">
          {navLinksdata.map(({ _id, title, link }) => (
            <p
              className="lg:font-semibold text-sm md:text-xl md:font-semibold lg:text-2xl hover:text-blue-700 hover:cursor-pointer duration-300 focus:text-yellow-500"
              key={_id}
            >
              <NavLink to={`/${link}`} exact activeClassName="active-link">
                {title}
              </NavLink>
            </p>
          ))}
        </div>
        <div className="flex md:hidden px-2">
          <GiHamburgerMenu
            className="w-6 h-6 text-yellow-500 cursor-pointer"
            onClick={() => setMenuOpen(!menuOpen)}
          />
        </div>
        <div className="w-20 md:w-36">
          <img src={logo} alt="Logo" loading="lazy"/>
        </div>
      </div>
      {menuOpen && (
        <div className="md:hidden">
          <div className=" p-4 bg-black fixed top-16 left-0 w-fit h-fit flex flex-col gap-2">
            {navLinksdata.map(({ _id, title, link }) => (
              <p
                className="text-xl font-semibold hover:text-blue-700 cursor-pointer duration-300"
                key={_id}
              >
                <NavLink to={`/${link}`} exact activeClassName="active-link">
                  {title}
                </NavLink>
              </p>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CommonNav;
